import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Stodlinjen from '../images/stodlinjen.svg';
import Spelinspektionen from '../images/spelinspektionen.svg';
import YearLimit from '../images/plus18.svg';

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(6)}px 0`,
    backgroundColor: '#181818',
  },
  yearLimit: {
    marginRight: 8,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <>
      <footer className={classNames(classes.footer, classes.layout)}>
        <Container>
          <Grid
            container
            spacing={3}
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xs>
              <Typography>
                Copyright © {new Date().getFullYear()} Bonusautomaten
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6">Spela ansvarsfullt.</Typography>
              <Box display="flex">
                <YearLimit height="35" className={classes.yearLimit} />{' '}
                <a
                  href="https://stodlinjen.se"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Stodlinjen height="40" />
                </a>
                <a
                  href="https://spelinspektionen.se"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Spelinspektionen height="40" />
                </a>
                <br />
              </Box>
              <Typography>Regler & villkor gäller.</Typography>
              <br />
              <Link href="https://www.spelpaus.se" rel="noopener">
                www.spelpaus.se
              </Link>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
}

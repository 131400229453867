import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import posed from 'react-pose';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
// import InputBase from '@material-ui/core/InputBase';
import Logo from '../../images/logo.svg';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    flexDirection: 'flex-end',
  },
  logo: {
    width: 52,
  },
  // title: {
  //   color: theme.palette.text.primary,
  //   textDecoration: 'none',
  // },
  headerButton: {
    marginLeft: 12,
    height: '100%',
    '&:first-of-type': {
      marginLeft: 0,
    },
    borderBottom: '2px solid transparent',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
    borderRadius: 0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    border: '1px solid #EBEBEB',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(3),
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      display: 'block',
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    fontWeight: 'bold',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 6),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 160,
      '&:focus': {
        width: 240,
      },
    },
  },
  navButtons: {
    marginLeft: 'auto',
  },
}));

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

function Header({ title }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appBar}
        position="static"
        boxshadow={0}
        color="inherit"
      >
        <AnimatedContainer>
          <Toolbar>
            {/* <IconButton
              className={classes.menuButton}
              color="primary"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton> */}
            <Link
              to="/"
              style={{
                textDecoration: 'none',
              }}
              className={classes.title}
            >
              <Logo className={classes.logo} />
              {/* <Typography variant="title">{title}</Typography> */}
            </Link>
            {/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Sök bonus"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div> */}
            <div className={classes.navButtons}>
              <Link
                to="/"
                style={{
                  textDecoration: 'none',
                }}
                className={classes.title}
              >
                <Button className={classes.headerButton}>Hem</Button>
              </Link>
              {/* <Button color="inherit" className={classes.headerButton}>
                Logga in
              </Button> */}
            </div>
          </Toolbar>
        </AnimatedContainer>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;

export const accent = '#f90000';
import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

const borderColor = 'rgb(225, 225, 225)';

const theme = createMuiTheme({
  body: {
    color: '#454545',
  },
  palette: {
    primary: red,
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#454545',
    },
    border: borderColor,
  },
  shadows: Array(25).fill(0), // the shadows array provided to createMuiTheme should support 25 elevations.
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: 'white',
        borderBottom: `1px solid ${borderColor}`,
      },
    },
    MuiButton: {
      contained: {
        textTransform: 'none',
        '&:hover': {
          textDecoration: 'none !important',
        },
      },
      containedPrimary: {
        color: 'white !important',
      },
      text: {
        textTransform: 'none',
        fontWeight: 600,
      },
      sizeLarge: {
        fontSize: 16,
        fontWeight: 'bold',
        padding: '12px 24px',
      },
    },
    MuiCard: {
      root: {
        border: `1px solid ${borderColor}`,
      },
    },
    MuiTypography: {
      h5: {
        fontWeight: '600 !important',
      },
    },
  },
  typography: {
    root: {
      fontWeight: 600,
      fontSize: 29,
      color: 'red',
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    useNextVariants: true,
  },
});

export default theme;
